import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Patient, CaseDetails, UserList } from '../models/Patient';
import {  Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PatientService {
	headers:any;
	authToken:any;
	caseDetails:any;
	patientDetails:any;
	userList:any;

	constructor(private http: HttpClient) { }


	loginAuth(grantType: string, client_id: string, client_secret:string, username:string, password: string) {
		grantType = 'client_credentials';
		client_id = '2cef3779-b46d-4ece-f1bc-63970aa035c1';
		client_secret = 'test-dev';
		// username = 'will';
		// password = 'will';
		this.headers = new HttpHeaders()
			.set('content-type', 'application/vnd.api+json')
			.set('Access-Control-Allow-Origin', '*')
			.set('Content-Security-Policy', 'self')
			.set('X-Frame-Options', 'SAMEORIGIN')
			.set('X-Content-Type-Options', 'nosniff')
			.set('Referrer-Policy', 'no-referrer, strict-origin-when-cross-origin')
			.set('Permissions-Policy', 'self')
			.set('Accept', "application/vnd.api+json");

		const body = {"grant_type":grantType,"client_id":client_id,"client_secret":client_secret}
		return this.authToken = this.http.post(environment.SERVER_URL_PREFIX + 'access_token', body, this.headers);
	}

	getUserList(accessToken:string):Observable<UserList[]>{
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		this.userList = this.http.get<UserList[]>(environment.REST_URL + 'get-user-list', {'headers': this.headers});
		return this.userList;
	}

	getDetails(caseNumber:string,accessToken:string):Observable<CaseDetails[]>{
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		const body = {"caseNumber":caseNumber}
		this.caseDetails = this.http.post<CaseDetails[]>(environment.REST_URL + 'get-case-record', body, {'headers': this.headers});
		return this.caseDetails;
	};

	createOrder(accessToken:string,orderName:string,contactName:string,permitType:string,orderPriority:string,orderStatus:string,description:string,assignedPerson:string){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		const body = {
			"name":orderName,
			"contactName":contactName,
			"permitType":permitType,
			"priority":orderPriority,
			"status":orderStatus,
			"description":description,
			"assignedPerson":assignedPerson
		}
		this.patientDetails = this.http.post(environment.REST_URL + 'create-patient-order', body, {'headers': this.headers});
		return this.patientDetails;
	};

}
