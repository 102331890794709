import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SupervisorLayoutComponent } from './layouts/supervisor/supervisor-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { AgentLayoutComponent } from './layouts/agent/agent-layout.component';
//import { ChatComponent } from '../app/chat/chat.component';
import { SeachpageComponent } from './seachpage/seachpage.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { AssistantComponent } from './assistant/assistant.component';
import { SupervisorleadsComponent } from './supervisorleads/supervisorleads.component';

export const AppRoutes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' }, 
  {
    path: '',
    component: AgentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'agent-historicalmetrics',
        loadChildren: './historicalmetrics/historicalmetrics.module#HistoricalmetricsModule'
      },
      {
        path: 'agent-advancedanalytics',
        loadChildren: './advancedanalytics/advancedanalytics.module#AdvancedanalyticsModule'
      },
      {
        path: 'supervisorleads',
        component: SupervisorleadsComponent
      }
     
    ]
  },
  {
    path: '',
    component: SupervisorLayoutComponent,
    children: [
      {
        path: 'supervisor',
        loadChildren: './supervisor/supervisor.module#SupervisorModule'
      },
      {
        path: 'supervisor-historicalmetrics',
        loadChildren: './historicalmetrics/historicalmetrics.module#HistoricalmetricsModule'
      },
      {
        path: 'supervisor-advancedanalytics',
        loadChildren: './advancedanalytics/advancedanalytics.module#AdvancedanalyticsModule'
      },
      {
        path: 'supervisor-reports',
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: 'search_page',
        component: SeachpageComponent
      },
      {
        path: 'compliance',
        component: ComplianceComponent
      },
      {
        path: 'stellar_assistant',
        component: AssistantComponent
      }
  ]
  },
  // {
  //   path: 'chat',
  //   component: ChatComponent,
  // },
];
