import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive, ViewChildren, forwardRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DateAdapter } from '@angular/material';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ROUTESS } from '../../supervisorsidebar/supervisorsidebar.component';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
declare var connect: any;

@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    myCPP: any;
    userstatus: any;
    timeString;
    connectvalue;
    status = [];
    localuserdetails;


    @ViewChild('app-navbar-cmp', { static: false }) button: any;

    constructor(location: Location, private renderer: Renderer, private element: ElementRef, private router: Router, private authService: AuthGuard) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;


    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    goAvailable() {
        // console.log("......inside available function");
        this.authService.goAvailable();
    }

    goOffline() {
        // console.log("......inside offline function");
        this.authService.goOffline();
    }

    logoutfunc() {
        var win = window.open("https://yogaalliance.awsapps.com/connect/logout", '1366002941508', 'height=568,width=370,left=600,top=120');
        localStorage.removeItem('loginuser');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserccpframe');
        localStorage.removeItem('username');
        localStorage.removeItem('iframer_loader_session');

        setTimeout(function(){
            win.close();
             window.location.href = 'https://yoga.stellarit-ai.com/';
        }, 5000)
                           
      }


    statuschange(agentStates) {
        for (var i = 0; i < agentStates.length; i++) {
            var type = agentStates[i].type;
            var name = agentStates[i].name;
            this.status.push(name);
        }
    }
    
    selecteddata(e) {
        var agent = AuthGuard.agentnew;
        var agentStates = AuthGuard.agentStates;
        var statusType;
        for (var i = 0; i < agentStates.length; i++) {
            if (agentStates[i].name == e.target.value) {
                statusType = agentStates[i];
                break;
            }
        }
        agent.setState(statusType, {
            success: function () {
                // console.log("Success in changing status");
            },
            failure: function () {
                // console.log("Failure in changing status");
            }
        });

    }



    ngOnInit() {

        var callimg = AuthGuard.callimage;
        if (AuthGuard.browserRefresh) {
            this.router.navigate(['/profile']);
        }
        // console.log(".......", JSON.parse(localStorage.getItem('currentUser')));
        this.localuserdetails = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.localuserdetails) {
            document.getElementById("yogadashboard").style.display = "none";
            window.location.href = 'https://stellarit-ai.com/';
        }

        var ccpframe = JSON.parse(localStorage.getItem('currentUserccpframe'));
        //  console.log("......inside navbar component.......", ccpframe);
        $('#ccpframepopup').append(ccpframe);

        // var agent = JSON.parse(localStorage.getItem('agent'));

        // $("#loginusername").text(agent.getName());
        // $("#userstaus").text(agent.getStatus().name);
        // $("#listofStatus").val(agent.getStatus().name);

        //this.statuschange(AuthGuard.agentStates);
        if (AuthGuard.agentStates) {
            this.statuschange(AuthGuard.agentStates);
        }

        // this.statuschange(AuthGuard.connectnew);
        // this.connectvalue = AuthGuard.connectnew;

        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            this.sidebarClose();

            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function () { //asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function () {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        if (!this.listTitles) {
            this.listTitles = ROUTES.filter(listTitle => listTitle);
        }
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === "/supervisor") {
            this.listTitles = [];
            this.listTitles = ROUTESS.filter(listTitle => listTitle);
        }
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {

                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    // console.log("nav sub title",subtitle)
                    //console.log('nav title',titlee)
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }

        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    search(){
        localStorage.setItem("search_option", "true");
        this.router.navigate(['/search_page']);
    }

    compliance(){
        this.router.navigate(['/compliance']);
    }


    videocallpage() {
        window.open('https://d3hb6zi5cijb2d.cloudfront.net/', '_blank');
     }
}
