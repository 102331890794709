import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import {MatSortModule} from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableExporterModule } from 'mat-table-exporter';
import  { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { SupervisorSidebarModule } from './supervisorsidebar/supervisorsidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SupervisorLayoutComponent } from './layouts/supervisor/supervisor-layout.component';
import { AppRoutes } from './app.routing';

import { TagCloudModule } from 'angular-tag-cloud-module';
import { CloudData, ZoomOnHoverOptions } from 'angular-tag-cloud-module';
import { LoginComponent } from './login/login.component';
import { Router } from '@angular/router';
import { forwardRef } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
PlotlyModule.plotlyjs = PlotlyJS;
import { ChartsModule } from 'ng2-charts';
import { AgentLayoutComponent } from './layouts/agent/agent-layout.component';
//import { ChatComponent } from '../app/chat/chat.component';
import { SeachpageComponent } from './seachpage/seachpage.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { AssistantComponent } from './assistant/assistant.component';
import { SupervisorleadsComponent } from './supervisorleads/supervisorleads.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
  ],
  declarations: [LoginComponent, SupervisorleadsComponent]
})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    HttpModule,
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    SupervisorSidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    TagCloudModule,
    MatSortModule,
    PlotlyModule,
    ChartsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ProfileComponent,
    SupervisorLayoutComponent,
    AgentLayoutComponent,
    SeachpageComponent,
    ComplianceComponent, 
    AssistantComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
