import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../../src/app/service/patient.service';
import { Patient } from '../models/Patient';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
var $ = require('jquery');
@Component({
  selector: 'app-supervisorleads',
  templateUrl: './supervisorleads.component.html',
  styleUrls: ['./supervisorleads.component.css']
})
export class SupervisorleadsComponent implements OnInit {
  public caseNumber1:string = '';
	public orderName:string = '';
	// public formCaseDetails = new FormGroup({});
	// public formOrderCreate = new FormGroup({});
	public authResponse:any;
	public accessToken:any;
	// public patient:Patient[] = [] as Patient[];
	case:any;
	orderCreate:any;
	userList:any;
	alluserlist = [];
	showDetailsDiv:boolean = false;

	constructor(private patientService: PatientService, private formBuilder: FormBuilder) {
		this.accessToken = '';
	}

	ngOnInit(): void {
		// this.formCaseDetails = this.formBuilder.group({
		// 	caseNumber1: ['', [Validators.required]]
		// });

		// this.formOrderCreate = this.formBuilder.group({
		// 	orderName: ['',[Validators.required]],
		// 	contactName: ['',[Validators.required]],
		// 	permitType: ['',[Validators.required]],
		// 	orderPriority: ['',[Validators.required]],
		// 	orderStatus: ['',[Validators.required]],
		// 	description: ['',[Validators.required]],
		// 	assignedPerson: ['',[Validators.required]],
		// });
		this.login();
	}

	public login(){
		localStorage.setItem("access_token", '');
		localStorage.setItem("refresh_token", '');
		this.patientService.loginAuth('', '', '', '', '').subscribe((resp:any) => {
			localStorage.setItem("access_token", resp.access_token);
			localStorage.setItem("refresh_token", resp.refresh_token);
			this.getUserList();
			return "Bearer "+JSON.stringify(localStorage.getItem('access_token'));
		});
	}

	getUserList(){
		this.accessToken = localStorage.getItem('access_token');
		this.showDetailsDiv = false;
		this.patientService.getUserList(this.accessToken).subscribe((data) => {
			this.userList = data;
			this.alluserlist = this.userList['userlist'];
		});
	}

	// getDetails() {
	// 	this.accessToken = localStorage.getItem('access_token');
	// 	this.showDetailsDiv = true;
	// 	if(this.formCaseDetails.valid){
	// 		this.caseNumber1 = this.formCaseDetails.value.caseNumber1;
	// 		this.patientService.getDetails(this.caseNumber1, this.accessToken).subscribe((data:any) => {
	// 			this.case = data.caseDetails;
	// 		});
	// 	}
	// 	else{
	// 		this.case = '';
	// 	}
	// }

	// createOrder():void{
	// 	this.accessToken = localStorage.getItem('access_token');
	// 	if(this.formOrderCreate.valid){
	// 		$('#errMsg').hide();
	// 		this.patientService.createOrder(this.accessToken, this.formOrderCreate.value.orderName, this.formOrderCreate.value.contactName, this.formOrderCreate.value.permitType, this.formOrderCreate.value.orderPriority, this.formOrderCreate.value.orderStatus, this.formOrderCreate.value.description,this.formOrderCreate.value.assignedPerson).subscribe((data:any) => {
	// 			this.orderCreate = data;
	// 			if(this.orderCreate=='Order creation is succesfull'){
	// 				$('#succMsg').show();
	// 				this.formOrderCreate.reset();
	// 				$('select').prop('selectedIndex', 0);
	// 			}
	// 			// console.log(this.orderCreate);
	// 		});
	// 	}
	// 	else{
	// 		this.orderCreate = '';
	// 		$('#succMsg').hide();
	// 		$('#errMsg').show();
	// 	}
	// }

}
