const SERVER_URL_PREFIX = 'http://3.213.115.113/permit-crm/public/legacy/Api/';
export const environment = {
  production: false,
  apiUrl: 'https://stellarit-ai.com/dashboardv/',
 // apiUrl: 'http://18.212.150.8:8080/dashboardv/',
  chaturl: 'https://stellarit-ai.com/',
  chat_url: 'https://dulcmpc09chle.cloudfront.net/',
  accessKeyId: '<<accessKeyId>>',
  secretAccessKey: '<<secretAccessKey>>',
  REST_URL: SERVER_URL_PREFIX + 'V8/custom/',
  SERVER_URL_PREFIX: SERVER_URL_PREFIX,
};
